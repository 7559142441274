import React from 'react';
import { SectionTitle } from '@config/importantInformation';
import { Button, Card, Carousel, CarReg } from '@rentecarlo/component-library';
import { PreviousCustomerCar } from '@services/api/quoteRtk';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setShouldShowSideBar } from '@redux/reducer/account/customer.actions';
import { useOptimizelyTrack } from '@hooks';
import { RootState } from '@redux/reducer';
import { resetQuote } from '@redux/reducer/quote/actions';
import { togglePrivacyPolicy } from '@redux/reducer/quote/legal.actions';

const Title = styled.h2`
  color: #364344;
  font-family: Graphie;
  font-weight: 700;
  text-align: left;
  padding-bottom: 8px;
  height: '64px';
`;

const StyledButton = styled(Button)`
  &:active {
    color: black;
    background-color: white;
  }
  &:hover {
    color: black;
    background-color: white;
  }
`;

const CarCard = ({
  car,
  triggerFunction,
  sendEvent,
}: {
  car: PreviousCustomerCar;
  triggerFunction: (policyUuid: string) => void;
  sendEvent: (event: string) => void;
}) => {
  const hasQuote = useSelector((state: RootState) => state.quote.save.uuid);
  const dispatch = useDispatch();

  const handleFastRepurchaseClick = () => {
    if (hasQuote) {
      dispatch(resetQuote());
      dispatch(togglePrivacyPolicy());
    }
    triggerFunction(car.policyUuid);
    sendEvent('repurchase_quote');
    dispatch(setShouldShowSideBar(false));
  };

  return (
    <Card>
      <div style={{ width: '330px', padding: '15px' }}>
        <Title id='quoteStart-title-carName'>{`${car.make} ${car.model}`}</Title>
        <div style={{ width: '45%', height: '32px' }}>
          <CarReg reg={car.registrationNumber} />
        </div>
        <div style={{ paddingTop: '15px' }}>
          <StyledButton
            id='quoteStart-button-repurchase'
            backgroundColor='white'
            textColor='black'
            height={42}
            paddingVertical={8}
            onClick={() => handleFastRepurchaseClick()}
          >
            Buy Cover
          </StyledButton>
        </div>
      </div>
    </Card>
  );
};

const PreviousVehicles: React.FC<{
  cars: PreviousCustomerCar[];
  triggerFunction: (policyUuid: string) => void;
}> = ({ cars, triggerFunction }) => {
  const sendEvent = useOptimizelyTrack();

  return (
    <>
      <SectionTitle id='quoteStart-title-temporaryDriverPostcodeText'>
        Previous Vehicles
      </SectionTitle>
      <Carousel
        id='quoteStart-carousel-previousVehicles'
        data-testid='quoteStart-carousel-previousVehicles'
        items={cars.map((car) => (
          <CarCard car={car} triggerFunction={triggerFunction} sendEvent={sendEvent} />
        ))}
      />
    </>
  );
};

export default PreviousVehicles;
