import React from 'react';
import styled from 'styled-components';
import SidebarTitle from '@atoms/titles/componentTitles/SidebarTitle';
import LinkArrow from '@atoms/links/LinkArrow';

const Container = styled.aside`
  box-shadow: 0px 1px 3px 0px #00000014;
  width: 100%;
`;

const HelpBox = styled.div`
  background: ${({ theme }) => theme.backgroundPrimary};
  border: 1px solid ${({ theme }) => theme.backgroundSecondary};
  border-radius: 8px;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
`;

const AltSidebarTitle = styled.h3`
  font-family: ${({ theme }) => theme.title};
  font-size: 24px;
  line-height: 30px;
  color: ${({ theme }) => theme.textPrimary};
`;

const RowWrapper = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const RowText = styled.b`
  font-family: ${({ theme }) => theme.standard};
  font-size: 14px;
  font-weight: 700;
  line-height: 22.65px;
  color: ${({ theme }) => theme.textPrimary};
  ${RowWrapper}:visited & {
    color: ${({ theme }) => theme.textPrimary};
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.fieldSecondary};
`;

const HelpfulLinkRow = ({ url, text, id }) => (
  <RowWrapper href={url}>
    <RowText id={id}>{text}</RowText>
    <LinkArrow direction='right' />
  </RowWrapper>
);

const RejectionSidebar = ({ faqUrl }) => (
  <Container>
    <HelpBox>
      <AltSidebarTitle id='rejection-title-helpfulLinksSidebar'>Helpful Links</AltSidebarTitle>
      <HelpfulLinkRow url={faqUrl} text='FAQs' id='helpfulLinks-link-faqs' />
      <Divider />
      <HelpfulLinkRow
        text='Help Centre'
        url='https://www.veygo.com/help'
        id='helpfulLinks-link-help'
      />
      <Divider />
      <HelpfulLinkRow text='Homepage' url='https://www.veygo.com' id='helpfulLinks-link-home' />
      <Divider />
    </HelpBox>
  </Container>
);

export default RejectionSidebar;
