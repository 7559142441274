import AmplitudeClient from './amplitude';

type ClientConfig = { enabled: boolean; apiKey: string };

type InitSettings = { amplitude: ClientConfig };

export class AnalyticsClient {
  private amplitudeClient: AmplitudeClient = new AmplitudeClient();

  public init(setup: InitSettings): void {
    this.amplitudeClient.init(setup.amplitude.enabled, setup.amplitude.apiKey);
  }

  public trackEvent(eventName: string, eventProperties?: object): void {
    this.amplitudeClient.trackEvent(eventName, eventProperties);
  }
}

const analyticsClient = new AnalyticsClient();

export default analyticsClient;
