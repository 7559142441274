/* eslint-disable @typescript-eslint/no-explicit-any */
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';
import { login } from '@reducer/account/login.actions';
import { changeLicenceType } from '@reducer/quote/licence.actions';
import { setProduct, setPaymentMethod } from '@reducer/quote/product.actions';
import { togglePrivacyPolicy } from '@reducer/quote/legal.actions';
import { resetQuote } from '@redux/reducer/quote/actions';
import { PaymentMethod } from '@utils/types';
import { RootState } from '@redux/reducer';
import { setQuoteForMyself, setShouldShowSideBar } from '@redux/reducer/account/customer.actions';
import Start from './Start';

export interface StateProps {
  amplifyLoaded: boolean;
  loggedIn: boolean;
  licenceType: string;
  productType: string;
  firstName: string;
  isQuoteForMyself: boolean;
}

const mapLicenceTypeToProduct = (licenceType: string): string => {
  switch (licenceType) {
    case 'uk_manual':
    case 'uk_auto':
      return 'csi';
    case 'uk_prov':
      return 'ldp';
    default:
      return 'csi';
  }
};

const mapStateToProps = (state: RootState): StateProps => ({
  amplifyLoaded: state.config.amplifyLoaded,
  loggedIn: state.account.login.loggedIn,
  licenceType: state.quote.licence.type,
  productType: state.quote.product.productType,
  firstName: state.quote.driver.firstName,
  isQuoteForMyself: state.account.customer.isQuoteForMyself,
});

interface DispatchProps {
  login: () => void;
  updateLicenceType: (licenceType: string) => void;
  setProductChoice: (licenceType: string) => void;
  setProduct: (type: any, licenceType: string) => void;
  submission: (productType: string) => void;
  resetQuote: () => void;
  setForMyself: (isQuoteForMyself: boolean) => void;
}

export type ComponentProps = StateProps & DispatchProps;

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  login: (): any => dispatch(login('start')),
  submission: (productType: string): void => {
    sessionStorage.setItem('quoteJourneyStart', Date.now().toString());
    dispatch(togglePrivacyPolicy());
    if (productType === 'newdriver') {
      dispatch(setPaymentMethod(PaymentMethod.subscription));
    }
    dispatch(push('quote-start'));
  },
  updateLicenceType: (licenceType: string): any => dispatch(changeLicenceType(licenceType)),
  setProductChoice: (licenceType: string): any =>
    dispatch(setProduct(mapLicenceTypeToProduct(licenceType))),
  setProduct: (licenceType: string): void => {
    if (licenceType === 'uk_prov') {
      dispatch(setProduct('ldp'));
    }
  },
  resetQuote: (): any => {
    dispatch(resetQuote());
    dispatch(setShouldShowSideBar(true));
  },
  setForMyself: (isQuoteForMyself: boolean): void => {
    dispatch(setQuoteForMyself(isQuoteForMyself));
  },
});

const mergeProps = (
  propsFromState: StateProps,
  propsFromDispatch: DispatchProps,
): ComponentProps => ({
  ...propsFromState,
  ...propsFromDispatch,
  setProduct: (type: any): void => {
    propsFromDispatch.setProduct(type, propsFromState.licenceType);
  },
});

const StartContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Start);

export default StartContainer;
