import React from 'react';
import { Field, formValueSelector, InjectedFormProps } from 'redux-form';
import TextInputField from '@atoms/inputs/TextInputField';
import Group from '@atoms/layout/form/Group';
import { FieldContainer } from '@rentecarlo/component-library';
import { SectionTitle } from '@config/importantInformation';
import { TempcoverReasonField } from '@organisms/modals/TempcoverReasonField';
import ReduxFieldValidation from '@services/formValidation';
import { ComponentType, useComponentId } from '@hooks';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

export const ElementContainer = styled.div`
  margin-top: 16px;
`;

export interface PassInProps {
  showError: boolean;
  error: string | undefined;
  formName: string;
}

export interface ValueProps {
  isNewCar: boolean;
  tempcoverModalOpen: boolean;
  productType: string;
  isQuoteForMyself: boolean;
}

export interface DispatchProps {
  openTempcoverReasonModal(e: React.SyntheticEvent): void;
  closeTempcoverReasonModal: () => void;
  changeToCsi: () => void;
}

const maxLength50 = ReduxFieldValidation.maxLength(50);

const ReasonForCover: React.FC<ValueProps & DispatchProps & PassInProps & InjectedFormProps> = ({
  formName,
  ...props
}) => {
  const creator = useComponentId();
  const tempcoverReason = useSelector((state) =>
    formValueSelector(formName)(state, 'tempcoverReason'),
  );
  const {
    isNewCar,
    openTempcoverReasonModal,
    closeTempcoverReasonModal,
    tempcoverModalOpen,
    changeToCsi,
    productType,
    showError,
    error,
    isQuoteForMyself,
  } = props;

  return (
    <Group data-testid='cover-reason'>
      <SectionTitle id={creator(ComponentType.TITLE, 'reasonForCover')}>
        {`What is the reason ${isQuoteForMyself ? 'you' : 'they'} need temporary cover?`}
      </SectionTitle>
      <FieldContainer
        id={creator(ComponentType.CONTAINER, 'tempCoverReason')}
        showErrorContainer={showError}
        showError={showError}
        error={error}
      >
        <Field
          id={creator(ComponentType.FIELD, 'tempcoverReasonSelection')}
          name='tempcoverReason'
          component={TempcoverReasonField}
          disabled={tempcoverReason && !isNewCar}
          validate={[ReduxFieldValidation.required]}
          onClose={closeTempcoverReasonModal}
          onOpen={(event: React.SyntheticEvent): void => {
            window.scrollTo(0, 0);
            openTempcoverReasonModal(event);
          }}
          modalOpen={tempcoverModalOpen}
          productType={productType}
          changeToCsi={changeToCsi}
          isQuoteForMyself={isQuoteForMyself}
        />
        {tempcoverReason === 'tc_other' && (
          <ElementContainer>
            <Field
              id={creator(ComponentType.FIELD, 'tempcoverAltReason')}
              name='tempcoverAltReason'
              component={TextInputField}
              placeholder='Please enter reason for cover'
              validate={[ReduxFieldValidation.required, maxLength50]}
            />
          </ElementContainer>
        )}
      </FieldContainer>
    </Group>
  );
};

export default ReasonForCover;
