import styled from 'styled-components';

import Colors from '@config/colors';

import GBLicenceIcon from '@assets/gb-licence-img-small.png';
import SearchIcon from '@assets/search-icn.svg';

interface RegistrationInput {
  hasError: boolean;
}

export const RegInputContainer = styled.div<RegistrationInput>`
  box-sizing: content-box;
  height: 64px;
  width: 100%;
  border: 1px solid ${Colors.grey.lines.light};
  border-radius: 4px;
  background-color: ${Colors.white};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  overflow: hidden;
  /* margin-bottom: ${({ hasError }): string => (hasError ? '16px' : '8px')}; */
  margin-bottom: 16px;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const LicenceIconWrapper = styled.div`
  width: 42px;
  background-color: ${Colors.teal};
`;

export const LicenceIcon = styled.img.attrs(() => ({
  src: GBLicenceIcon,
}))`
  height: 64px;
  width: 42px;
`;

export const RegistrationInputWrapper = styled.div`
  width: 100%;
`;

export const RegistrationInput = styled.input`
  font-size: 32px;
  font-family: UKNumberPlate;
  outline: none;
  width: 100%;
  height: 64px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: ${Colors.white};
  cursor: inherit;
  text-transform: uppercase;
  color: ${Colors.outerSpace};
  border-width: 0px;
  -webkit-appearance: none;
  text-align: center;
  padding: 0px;
  flex: 1;

  &::placeholder {
    color: ${Colors.grey.faded};
  }

  &:focus::placeholder {
    color: transparent;
  }

  /* Fix Chrome issue with outline/ border above placeholder font */
  filter: blur(0);
`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DividerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 423px) {
    display: none;
`;

export const Divider = styled.div`
  height: 33px;
  width: 1px;
  background: ${Colors.grey.lines.light};
`;

export const SearchButtonWrapper = styled.div`
  width: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 423px) {
    width: 48px;
    margin-right: 16px;
  }
`;

export const SearchButtonText = styled.span`
  @media only screen and (max-width: 423px) {
    display: none;
  }
`;

export const SearchButtonIcon = styled.img.attrs(() => ({
  src: SearchIcon,
}))`
  display: none;
  @media only screen and (max-width: 423px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;
