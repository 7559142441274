import React from 'react';
import { reduxForm } from 'redux-form';

import QuoteStep from '@templates/QuoteStep';
import { ComponentType, useComponentId } from '@hooks';
import driverdetailsIcon from '@assets/manual.svg';
import carIcon from '@assets/car-front.svg';
import receiptIcon from '@assets/receipt.svg';
import certificateIcon from '@assets/certificate.svg';
import { InformationBox } from '@rentecarlo/component-library';

import FastRepurchaseSummary from './FastRepurchaseSummary';
import { ComponentProps } from './ConfirmFastRepurchaseDetails.container';

const ConfirmFastRepurchaseDetails: React.FC<ComponentProps> = (props) => {
  const creator = useComponentId();
  const {
    driverDetailsData,
    vehicleInformationData,
    payableExcessData,
    drivingHistoryData,
    underwriting,
    driverDetailsEdit,
    vehicleInformationEdit,
    payableExcessEdit,
    drivingHistoryEdit,
    submission,
  } = props;

  const canEditDriver = true;

  return (
    <QuoteStep
      id={creator(ComponentType.COMPONENT, 'quoteStep')}
      includeStepPrefix={false}
      nextAction={submission}
      nextLabel='Confirm'
      stepTitle='Confirm details'
      stepSubtitle='Please check these details are correct'
      form
      formErrors={{}}
    >
      <FastRepurchaseSummary
        title='Driver details'
        pillButtonAction={driverDetailsEdit}
        hidePillButton={!canEditDriver}
        icon={driverdetailsIcon}
        data={driverDetailsData}
      />
      <FastRepurchaseSummary
        title='Vehicle information'
        pillButtonAction={vehicleInformationEdit}
        hidePillButton={false}
        icon={carIcon}
        data={vehicleInformationData}
      />
      <FastRepurchaseSummary
        title='Driving history'
        pillButtonAction={drivingHistoryEdit}
        hidePillButton={false}
        icon={certificateIcon}
        data={drivingHistoryData}
      />
      <FastRepurchaseSummary
        title='Payable excess'
        pillButtonAction={payableExcessEdit}
        hidePillButton={!underwriting}
        icon={receiptIcon}
        data={payableExcessData}
      >
        <InformationBox
          id={creator(ComponentType.FIELD, 'mobileImportantInformation')}
          type='important'
        >
          The excess is the amount you pay in the event of any claim on this policy, regardless of
          who is responsible for the incident.
        </InformationBox>
      </FastRepurchaseSummary>
    </QuoteStep>
  );
};

export default reduxForm({
  form: 'confirmFastRepurchaseDetailsForm',
})(ConfirmFastRepurchaseDetails);
