import { v4 as uuidv4 } from 'uuid';
import { all, call, delay, put, select } from 'redux-saga/effects';

import deleteOldCsrf from '@services/api/csrf';
import { setSessionCookie, getCookie } from '@services/cookies';

import { setUserField } from '@reducer/quote/user.actions';
import {
  fetchRestrictedPeriodsSuccess,
  fetchRestrictedPeriodsFailure,
  amplifyLoaded,
  setOptimiselyAttribute,
} from '@reducer/config/actions';
import * as AmplifyService from '@services/amplify';
import getAffiliate from '@services/getAffiliateTag';
import { optimizelyClient } from '@services/clients/optimizely';
import { initGA } from '@services/ecommerceTracking';
import * as Sentry from '@sentry/react';
import handleError from '@redux/utils/errorHandler';
import analyticsClient from '@utils/analytics';
import { initStore, checkSession } from '../init';
import { restrictedPeriodsApi } from '../../services/api';

export function* retrieveRestrictedPeriods() {
  try {
    const response = yield call(restrictedPeriodsApi.get);
    yield put(fetchRestrictedPeriodsSuccess(response));
  } catch (error) {
    handleError(error);
    yield put(fetchRestrictedPeriodsFailure(error));
  }
}

function* initialiseAmplifyService() {
  try {
    yield call(() => AmplifyService.initialise());
    yield put(amplifyLoaded());
  } catch (error) {
    handleError(error);
  }
}

function* deleteCsrf() {
  try {
    yield call(deleteOldCsrf);
  } catch (error) {
    handleError(error);
  }
}

function* setUserIdentifier() {
  let userIdentity = getCookie('user_identity');

  if (userIdentity === undefined) {
    userIdentity = uuidv4();

    const cookieYesCookie = getCookie('cookieyes-consent');
    if (cookieYesCookie && cookieYesCookie.includes('functional:yes')) {
      setSessionCookie('user_identity', userIdentity);
    }
  }
  yield put(setOptimiselyAttribute('user_identity', userIdentity));
}

function initialiseAnalytics() {
  const cookieYesCookie = getCookie('cookieyes-consent');

  const amplitudeEnabled = Boolean(cookieYesCookie) && cookieYesCookie.includes('analytics:yes');

  analyticsClient.init({
    amplitude: { enabled: amplitudeEnabled, apiKey: process.env.REACT_APP_AMPLITUDE_ID },
  });
}

export function* runInit() {
  initGA();
  yield delay(100);
  yield deleteCsrf();

  yield put(initStore());

  const affiliateId = getAffiliate();
  if (affiliateId) {
    yield put(setUserField('affiliateId', affiliateId));
  }
  yield retrieveRestrictedPeriods();
  yield setUserIdentifier();
  initialiseAnalytics();
  yield initialiseAmplifyService();

  yield put(checkSession());
}
