/* eslint-disable @typescript-eslint/ban-types */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, WrappedFieldProps } from 'redux-form';

import { isMobile } from '@services/isMobile';
import ReduxFieldValidation from '@services/formValidation';
import ToggleButtonsOwner from '@molecules/inputs/ToggleButtonsOwner';
import { Card, PillButton, FieldContainer, CardUtils } from '@rentecarlo/component-library';

import { useOptimizelyFlag } from '@hooks';

import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import SectionSubTitle from '@atoms/titles/componentTitles/SectionSubTitle';
import Group from '@atoms/layout/form/Group';

import CardCarIcon from '@assets/car-icn-tc.svg';
import Plus from '@assets/plus.svg';
import { Car } from '@pages/quote/CarDetails/CarDetails.container';
import CarModal from '@organisms/modals/CarModal';
import CarSearch from '@organisms/blocks/CarSearch';
import CarDetailsCard from '@organisms/blocks/CarDetailsCard';
import { RootState } from '@redux/reducer';

type Props = {
  setProduct: Function;
  toggleStatus: Function;
  setDriverOwner: Function;
  submitFailed: boolean;
  onModalOpen: Function;
  licenceType: string;
  isQuoteForMyself: boolean;
  handleCarSubmit: (func: (arg0: string) => void) => void;
};

type OwnerOrDriverSelectorProps = {
  setProduct: Function;
  setDriverOwner: Function;
  id: string;
  disabled: boolean;
  isFullLicenceType: boolean;
  isQuoteForMyself: boolean;
};

type OwnerDriverSuscriptionProps = {
  id: string;
  type: string;
};

const ownerDriverSuscriptionMapper: Array<OwnerDriverSuscriptionProps> = [
  {
    id: 'owner',
    type: 'owner',
  },
  {
    id: 'driver',
    type: 'driver',
  },
];

const OwnerOrDriverSelector: React.FC<OwnerOrDriverSelectorProps & WrappedFieldProps> = ({
  input: { value, onChange },
  setProduct,
  setDriverOwner,
  meta: { warning, error, submitFailed },
  id,
  disabled,
  isQuoteForMyself,
}) => (
  <FieldContainer
    id={id}
    showErrorContainer={submitFailed && error}
    showError={!!((submitFailed || value) && error)}
    error={error}
  >
    <ToggleButtonsOwner
      value={value}
      onSelect={(selectedValue: string): void => {
        const ownerDriverSubsObject = ownerDriverSuscriptionMapper.find(
          (mapper: OwnerDriverSuscriptionProps) => mapper.id === selectedValue,
        );
        onChange(selectedValue);
        setProduct(ownerDriverSubsObject?.type);
        setDriverOwner(ownerDriverSubsObject?.type);
      }}
      error={error}
      warn={warning}
      disabled={disabled}
      groupName='ownerDriver'
      isQuoteForMyself={isQuoteForMyself}
    />
  </FieldContainer>
);

const AboutCar: React.FC<Props> = ({
  toggleStatus,
  setProduct,
  setDriverOwner,
  submitFailed,
  onModalOpen,
  licenceType,
  isQuoteForMyself,
  handleCarSubmit,
}) => {
  const productType = useSelector((state: RootState) => state.quote.product.productType);
  const car = useSelector((state: RootState): Car | null => state.lookup.car);
  const registrationNumber = useSelector((state: RootState) => state.lookup.registrationNumber);

  const [showCarModal, setShowCarModal] = useState(false);

  const showCarModalOn = (): void => {
    const hasErrors = onModalOpen();
    if (!hasErrors) {
      setShowCarModal(true);
    }
  };

  const showCarModalOff = (): void => {
    setShowCarModal(false);
  };

  const enableAddCarOnQuoteStartForm = useOptimizelyFlag('TEMP_ADD_CAR_ON_QUOTE_START_FORM')
    .enabled;

  return (
    <>
      <Group id='quoteStart-component-groupCarToInsure'>
        <SectionTitle id='quoteStart-title-carToInsure'>
          Tell us about the car {isQuoteForMyself ? "you'd" : "they'd"} like to insure
          <span id='quoteStart-image-carIcon' role='img' aria-label='car'>
            {' '}
            🚗
          </span>
        </SectionTitle>
        {['csi', 'tc', 'newdriver'].includes(productType) && (
          <SectionSubTitle id='quoteStart-subtitle-borrowingTip'>
            {`TIP: If ${
              isQuoteForMyself ? 'your' : 'their'
            } partner or close family member is the registered keeper, we still call this
            borrowing`}
          </SectionSubTitle>
        )}
        <Field
          id='status'
          name='status'
          component={OwnerOrDriverSelector}
          validate={[ReduxFieldValidation.required]}
          setProduct={setProduct}
          setDriverOwner={setDriverOwner}
          toggleStatus={toggleStatus}
          isFullLicenceType={licenceType !== 'uk_prov'}
          isQuoteForMyself={isQuoteForMyself}
        />
      </Group>
      <Group id='quoteStart-component-groupCarDetails'>
        {enableAddCarOnQuoteStartForm ? (
          <CarSearch
            isVehicleLookup
            submitFailed={submitFailed}
            isQuoteForMyself={isQuoteForMyself}
            handleCarSubmit={handleCarSubmit}
            saveQuote
          />
        ) : (
          <>
            {car ? (
              <CarDetailsCard
                car={car}
                registrationNumber={registrationNumber}
                showNotch={false}
                onEdit={(event: React.ChangeEvent<HTMLInputElement>): void => {
                  showCarModalOn();
                  event.preventDefault();
                }}
              />
            ) : (
              <Card>
                <CardUtils.CardWrapper>
                  <CardUtils.StyledCard>
                    <CardUtils.Wrapper>
                      <CardUtils.CardTitleWrapper>
                        <CardUtils.CardTitleIcon icon={CardCarIcon} />
                        <CardUtils.CardTitleTitle id='quoteStart-title-addCarCard' fontSize={18}>
                          {isMobile.any()
                            ? `Add ${isQuoteForMyself ? 'your' : 'their'} car`
                            : `Add the car ${isQuoteForMyself ? 'you' : 'they'} want to insure`}
                        </CardUtils.CardTitleTitle>
                      </CardUtils.CardTitleWrapper>
                    </CardUtils.Wrapper>

                    <CardUtils.CardSubtitleWrapper id='quoteStart-subtitle-requireCarReg'>
                      All we need at this stage is the car registration
                    </CardUtils.CardSubtitleWrapper>

                    <CardUtils.ChildrenWrapper>
                      <CardUtils.LeftMarginWrapper>
                        <PillButton
                          id='quoteStart-button-addCar'
                          data-testid='quote-start-add-car'
                          leftImage={Plus}
                          onClick={(event): void => {
                            showCarModalOn();
                            event.preventDefault();
                          }}
                        >
                          Add car
                        </PillButton>
                      </CardUtils.LeftMarginWrapper>
                    </CardUtils.ChildrenWrapper>
                  </CardUtils.StyledCard>
                </CardUtils.CardWrapper>
              </Card>
            )}
            <CarModal
              show={showCarModal}
              close={(): void => {
                showCarModalOff();
              }}
            >
              <CarSearch
                isVehicleLookup
                submitFailed={submitFailed} /* || submitError */
                skipValidation={!showCarModal}
                isQuoteForMyself={isQuoteForMyself}
              />
            </CarModal>
          </>
        )}
      </Group>
    </>
  );
};

export default AboutCar;
