import { call, put, select, takeEvery, take } from 'redux-saga/effects';
import { push, LOCATION_CHANGE } from 'connected-react-router';
import _ from 'lodash';

import CustomersClient from '@services/clients/customers';
import { remoteToLocalLicenceFields } from '@services/transformers/customers';
import { setProduct } from '@reducer/quote/product.actions';
import { setUserField } from '@reducer/quote/user.actions';
import { statedTestPassDate } from '@reselectors/account';
import { setLicenceField, changeLicenceType } from '@reducer/quote/licence.actions';
import handleError from '@redux/utils/errorHandler';
import { selectLocation } from './tools';
import { address as addressApi, customer as customerApi } from '../../services/api';
import {
  PROFILE_FROM_QUOTE,
  CUSTOMER_FETCH_ME_REQUEST,
  CUSTOMER_SAVE_REQUEST,
  types,
  fetchMe,
  fetchMeSuccess,
  fetchMeFailure,
  saveCustomerSuccess,
  saveCustomerFailure,
  setCustomerField,
  upgradeCustomerSuccess,
  updateDriverFromQuote,
} from '../reducer/account/customer.actions';
import { types as loginTypes, logout } from '../reducer/account/login.actions';

function licenceAlreadyExists(data) {
  return data.uw_failure_reasons.some((elem) => elem.reason === 'FR13');
}

export const getAccountLicenceType = (state) => state.account.customer.licence.type;
export const getCurrentPage = (state) => state.router.location.pathname;
export const getProductType = (state) => state.quote.product.productType;

export function* fetchMeSaga() {
  try {
    let data = yield call(CustomersClient.getV3);
    // Call csi backend to get additional product-related customer data
    const additionalData = yield call(customerApi.retrieveCustomerAdditionalData);
    data = _.merge({}, data, additionalData);

    yield put(fetchMeSuccess(data));
    // Set the product when the customer logs in
    // Currently we do it based on licence type seleciton on quote start and is defaulted
    // to CSI, when logging in as an LDP user, it means it's defaulted to CSI.
    const page = yield select(getCurrentPage);
    if (page.includes('start')) {
      const productType = yield select(getProductType);
      if (data.licence_type === 'ukp' && productType !== 'newdriver') {
        yield put(setProduct('ldp'));
      }
    }
    yield put(setUserField('status', 'driver'));
  } catch (error) {
    yield put(fetchMeFailure(error));
    if (error.status === 404) {
      yield put(logout());
    }
  }
}

export const getCustomer = (state) => state.account.customer;
export const getLoggedIn = (state) => state.account.login.loggedIn;
export const getAddress = (state) => state.account.address[0];
export const getRegistrationUuid = (state) => state.account.register.customerUuid;
export const getRegistrationEmail = (state) => state.account.register.email;
export const getRegistrationPassword = (state) => state.account.register.password;
export const getRegistrationToken = (state) => state.account.register.registrationToken;
export const getLoginToken = (state) => state.account.login.token;
export const getQuote = (state) => state.quote;

export function* saveCustomerSaga(action) {
  const registrationUuid = yield select(getRegistrationUuid);
  const uuid = registrationUuid;
  const quote = yield select(getQuote);
  yield put(updateDriverFromQuote(uuid, quote));
  const addressData = yield select(getAddress);

  const customerData = yield select(getCustomer);
  try {
    let data = yield call(CustomersClient.putV3, { ...customerData, address: addressData });
    // Call csi backend to get additional product-related customer data
    const additionalData = yield call(customerApi.retrieveCustomerAdditionalData);
    data = _.merge({}, data, additionalData);

    yield put(saveCustomerSuccess(data));
    yield put(setCustomerField('hasEdited', false));
    yield put(setCustomerField('hasEditedPersonalDetails', false));
    yield put(push(action.page));
  } catch (error) {
    handleError(error);
    yield put(saveCustomerFailure(error));
  }
}

export function* doFetchMe() {
  const loggedIn = yield select(getLoggedIn);
  if (loggedIn) yield put(fetchMe());
}

export function* watchCustomer() {
  yield takeEvery(CUSTOMER_FETCH_ME_REQUEST, fetchMeSaga);
  yield takeEvery(CUSTOMER_SAVE_REQUEST, saveCustomerSaga);
  yield takeEvery(
    [
      loginTypes.LOGIN_SUCCESS,
      selectLocation('/account/connections'),
      selectLocation('/documents'),
      selectLocation('/account'),
    ],
    doFetchMe,
  );
}
