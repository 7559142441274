import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';

import { reduxForm, getFormSyncErrors } from 'redux-form';

import { RootState } from '@redux/reducer';
import { saveCustomerAndGoTo, setCustomerField } from '@reducer/account/customer.actions';
import { prefillDriver } from '@reducer/quote/user.actions';
import { setDriverField } from '@reducer/quote/driver.actions';
import { getDriverName } from '@reselectors/quote';
import { addressToList } from '@services/address';
import { formatDate } from '@services/date';
import licenceLabelFromType from '@services/licenceTypes';

import { optimizelyClient } from '@services/clients/optimizely';

import DriverDetailsSummary, { DriverDetailsSummaryProps } from './DriverDetailsSummary';

const mapStateToProps = (state: RootState) => ({
  address: addressToList(state.quote.driver.address),
  birthdate: formatDate(state.quote.driver.birthdate),
  canEdit: state.account.customer.canEdit,
  display30DayEditModal: state.account.customer.display30DayEditModal,
  driverFullName: getDriverName(state),
  email: state.quote.driver.email,
  hasEditedPersonalDetails: state.account.customer.hasEditedPersonalDetails,
  isLoggedIn: state.account.login.loggedIn,
  licenceNumber: state.quote.licence.licenceNumber,
  licenceType: licenceLabelFromType(state.quote.licence.type),
  occupation: state.quote.driver.occupation,
  phone: state.quote.driver.phoneNumber,
  formErrors: getFormSyncErrors('driverDetailsSummaryForm')(state),
  productType: state.quote.product.productType,
  paymentMethod: state.quote.product.paymentMethod,
  userIdentity: state.config.optimizelyAttributes.user_identity,
});

const nextPath = (productType: string, paymentMethod: string, userIdentity: string) => {
  const dtcCrossSell = optimizelyClient.isFeatureEnabled(
    'TEMP_ENABLE_DTC_NEWDRIVER_CROSS_SELL_SUBS',
    userIdentity,
    {
      user_identity: userIdentity,
    },
  );

  const acceptedSubscriptionProducts = ['tc'];

  if (dtcCrossSell) {
    acceptedSubscriptionProducts.push('csi');
  }

  if (
    productType === 'newdriver' ||
    (acceptedSubscriptionProducts.includes(productType) && paymentMethod === 'subscription')
  ) {
    return 'driving-profile';
  }

  return 'driving-history';
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  confirmAction: (
    props: DriverDetailsSummaryProps,
    formValues: Record<string, string>,
    mobileNumberUpdated: boolean,
  ) => {
    const path = nextPath(props.productType, props.paymentMethod, props.userIdentity);
    if (mobileNumberUpdated) {
      dispatch(setDriverField('phoneNumber', formValues.phoneNumber));
    }
    if (props.hasEditedPersonalDetails && props.isLoggedIn) {
      dispatch(saveCustomerAndGoTo(path));
    } else if (mobileNumberUpdated) {
      dispatch(saveCustomerAndGoTo(path));
    } else {
      dispatch(push(path));
    }
  },
  edit: () => dispatch(push('/about-you')),
  onAccept: (props: DriverDetailsSummaryProps) => {
    const path = nextPath(props.productType, props.paymentMethod);
    dispatch(saveCustomerAndGoTo(path));
  },
  onCancel: () => {
    dispatch(prefillDriver());
    dispatch(setCustomerField('hasEdited', false));
    dispatch(setCustomerField('hasEditedPersonalDetails', false));
  },
});

const DriverDetailsSummaryForm = reduxForm({
  form: 'driverDetailsSummaryForm',
})(DriverDetailsSummary);

export default connect(mapStateToProps, mapDispatchToProps)(DriverDetailsSummaryForm);
