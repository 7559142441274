import Debug from 'debug';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import { AppLinks, Info, PromptCard, PromptBox } from '@rentecarlo/component-library';

import QuoteStep from '@templates/QuoteStep';
import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import Group from '@atoms/layout/form/Group';
import PricingElement from '@molecules/blocks/PricingElement';
import AwinTrackingTags from '@tools/affiliates/AwinTrackingTags';
import BluePurpleBackground from '@assets/prompt-box-background.svg';
import { useAppSelector, useOptimizelyFlag, useOptimizelyTrack } from '@hooks';
import { getBuyapowa } from '@services/buyapowaProvider';
import { useCheckoutQuery } from '@services/api/quoteRtk';
import { getCookie, eraseCookie } from '@services/cookies';

import { RootState } from '@redux/reducer';
import { setAppField } from '@redux/reducer/app/actions';

import TelematicsAppDownloadModal from '@organisms/modals/TelematicsAppDownloadModal';
import LearnerToNewDriverModal from '@organisms/modals/LearnerToNewDriverModal';

const debug = Debug('veygo:pages:quote:ThankYou');

const PromptContainer = styled.div`
  margin-bottom: 32px;
  text-align: center;
`;

const PromptTitle = styled.h3`
  color: #ffffff;
  font-family: Graphie;
  font-size: 24px;
  font-weight: 800;
  letter-spacing: normal;
  line-height: 30px;
  margin-bottom: 16px;
`;

export const PromptText = styled.p`
  color: #ffffff;
  font-family: proxima-soft;
  font-size: 14px;
  line-height: 22.65px;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const SubscriptionInfoBox = styled.div`
  margin-bottom: 32px;
`;

const ReferralContainer = styled.div`
  div {
    background: #005465;
  }
  h2 {
    margin-bottom: 6px;
  }
  p {
    font-size: 12px;
    margin-bottom: 6px;
  }
  button {
    width: 100%;
    text-align: center;
    div {
      background: #f3f4f7;
      color: #005465;
    }
  }
`;

interface Data {
  driverEmail: string;
  driverFirstName: string;
  driverLastName: string;
  isFirstPurchase: boolean;
  isSubscription: boolean;
  price: string;
  productType: string;
  referenceNumber: string;
  utmSource: string;
  commissionGroup: string;
  durationStart: string;
  durationEnd: string;
  subscriptionReferenceNumber: string;
}

const ThankYou: React.FC = () => {
  const quoteUuid = useAppSelector((state) => state.quote.purchasedQuote.uuid);
  const amplifyLoaded = useAppSelector((state) => state.config.amplifyLoaded);

  if (!quoteUuid) {
    // This used to be an error, but it was firing tens of thousands of times.
    // That's a tad concerning, but we do know that the page will render when this happens,
    // just without the quote summary section (see tests.)
    // We don't know whether (A) the quote UUID is missing only on the first render
    // and a subsequent render is fine, or (B) whether the user's genuinely missing
    // the quote UUID because they're reloading the /thank-you page directly.
    // In any case, having it as an error ran out the Sentry quota very quickly.
    debug('quoteUuid is missing in ThankYou page');
  }

  const oneLinkUrl = 'https://veygoppc.onelink.me/QP8O/ggjm8qjk';

  const Buyapowa = getBuyapowa();

  // Retrieve policy checkout data
  const { data: untypedData, error, isSuccess } = useCheckoutQuery(
    quoteUuid,
    // Don't run this unless Amplify is ready
    { skip: !amplifyLoaded },
  );

  const data = untypedData as Data;

  const dataReady = isSuccess && amplifyLoaded;

  const quoteSummaryHeader = data?.isSubscription
    ? 'We will take a payment of'
    : 'We’ve taken a single payment of';

  useEffect(() => {
    // Don't run this effect unless everything's ready
    if (!dataReady || !data) {
      return;
    }

    const referrerCookieAttached = getCookie('bp_e') !== undefined;
    const isOverThirtyPounds = parseFloat(data.price) >= parseFloat('30');
    debug(
      `Should track Buyapowa? isFirstPurchase: ${data.isFirstPurchase}, isOverThirtyPounds: ${isOverThirtyPounds}, referrerCookieAttached: ${referrerCookieAttached}`,
    );
    if (data.isFirstPurchase && referrerCookieAttached && isOverThirtyPounds) {
      debug('Buyapowa tracking initiated');
      const buyapowa = new Buyapowa({ url: process.env.REACT_APP_BUYAPOWA_URL, market: 'veygo' });
      // You might think, hey! how do Buyapowa know who referred the customer?
      // Well, we have a url parameter that is passed in payment.js.
      // This parameter is automatically pulled in by the below function.
      buyapowa.track({
        customer_name: `${data.driverFirstName}, ${data.driverLastName}`,
        customer_email: `${data.driverEmail}`,
        order_value: `${data.price}`,
        order_number: `${quoteUuid}`,
        locale: 'en',
      });
      eraseCookie('bp_e');
    }
  }, [dataReady, Buyapowa, data, quoteUuid]);

  const telematicsModalFlag = useOptimizelyFlag('TEMP_ENABLE_TELEMATICS_APP_MODAL');

  const [showTelematicsAppDownloadModal, setShowTelematicsAppDownloadModal] = useState(false);
  const hasShownTelematicsModal = useRef(false);

  useEffect(() => {
    if (!telematicsModalFlag.clientReady || !dataReady) return;

    if (
      data.productType === 'NDS' &&
      !hasShownTelematicsModal.current &&
      telematicsModalFlag.enabled
    ) {
      setTimeout(() => {
        setShowTelematicsAppDownloadModal(true);
        hasShownTelematicsModal.current = true;
      }, 1000);
    }
  }, [dataReady, data, telematicsModalFlag]);

  const dispatch = useDispatch();
  const hasSeenLearnerNDTest = useSelector((state: RootState) => state.app.hasSeenLearnerNDTest);

  const [showLearnerToNewDriverModal, setShowLearnerToNewDriverModal] = useState(false);
  const shownLearnerNDModal = useRef(false);

  const sendEvent = useOptimizelyTrack();
  const showLearnerNewDriverModal = useOptimizelyFlag('TEMP_LEARNER_TO_NEWDRIVER_MODAL');

  useEffect(() => {
    if (!showLearnerNewDriverModal.clientReady || !dataReady) return;

    if (
      data.productType === 'LDP' &&
      data.isSubscription &&
      !shownLearnerNDModal.current &&
      showLearnerNewDriverModal.enabled &&
      showLearnerNewDriverModal.variationKey === 'post_purchase'
    ) {
      setTimeout(() => {
        setShowLearnerToNewDriverModal(true);
        sendEvent('learner_to_newdriver_show');
        shownLearnerNDModal.current = true;
      }, 2000);
    }
  }, [sendEvent, showLearnerNewDriverModal, dataReady, data, hasSeenLearnerNDTest]);

  const closeLearnerToNewDriverModal = () => {
    setShowLearnerToNewDriverModal(false);
    dispatch(setAppField('hasSeenLearnerNDTest', true));
  };

  return (
    <>
      <TelematicsAppDownloadModal
        visible={showTelematicsAppDownloadModal}
        close={() => setShowTelematicsAppDownloadModal(false)}
      />
      <LearnerToNewDriverModal
        show={showLearnerToNewDriverModal}
        onClose={closeLearnerToNewDriverModal}
        price={Number(data?.price)}
      />
      <QuoteStep
        id='thankYou-component-QuoteStep'
        includeStepPrefix={false}
        stepTitle='Payment complete'
        stepSubtitle='Thank you for choosing Veygo. Please check your email for your policy documents.'
      >
        {data?.productType !== 'NDS' && (
          <div id='appLinks'>
            <AppLinks
              background={BluePurpleBackground}
              buttonText='Get the app'
              displayButton
              id='AppLinksWithButton'
              title='Download the Veygo app for quick access to your policy documents'
              url={oneLinkUrl}
            />
          </div>
        )}

        {data?.productType === 'NDS' && (
          <PromptContainer>
            <PromptCard background={BluePurpleBackground}>
              <PromptTitle id='thankYou-title-promptNDSDownloadApp'>
                Do not drive without downloading the 'Veygo New Driver' app!
              </PromptTitle>
              <PromptText id='thankYou-text-promptNDSDownloadApp'>
                But don't worry we will send you an{' '}
                <Bold id='thankYou-text-promptNDSDownloadAppBoldSMS'>SMS</Bold> with a{' '}
                <Bold id='thankYou-text-promptNDSDownloadAppBoldDownload'>download</Bold> link
                shortly.
              </PromptText>
            </PromptCard>
          </PromptContainer>
        )}
        {!error && dataReady && quoteUuid && (
          <Group id='thankYou-container-group' style={{ marginBottom: '32px' }}>
            <SectionTitle id='thankYou-title-policyDetails'>Your policy details</SectionTitle>{' '}
            <PricingElement
              duration={{
                startDateTime: data?.durationStart,
                endDateTime: data?.durationEnd,
              }}
              price={data?.price}
              referenceNumber={data?.referenceNumber}
              subscriptionReferenceNumber={data?.subscriptionReferenceNumber}
              isSubscription={data?.isSubscription}
              quoteSummaryHeader={quoteSummaryHeader}
              quoteSummaryFooter='Your price includes insurance premium tax at 12% (where applicable)'
              isTelematics={data?.productType === 'NDS'}
            />
          </Group>
        )}

        {!error && data?.isSubscription && (
          <SubscriptionInfoBox id='thankYou-container-subscriptionInfo'>
            <Info id='thankYou-text-cancelInfo'>
              You can cancel your monthly subscriptions at any time. Just log in to your Veygo
              account and let us know. Your insurance will be stopped and we won't charge you again
              until you ask us to.
            </Info>
          </SubscriptionInfoBox>
        )}

        {data?.utmSource === 'awin' && (
          <AwinTrackingTags
            order_subtotal={data?.price}
            order_ref={data?.referenceNumber}
            commission_group={data?.commissionGroup}
            product={data?.productType}
          />
        )}

        <ReferralContainer>
          <PromptBox
            id='referAFriendPromptbox'
            title='Refer a friend - Get £10 each 🙌'
            text='Share Veygo with your friends and you’ll both get a £10 voucher when they buy a policy.'
            buttonText='Get your sharing link'
            titleLarge
            marginTop='32px'
            textFontSize='12px'
            onClick={() =>
              (window.location.href = `${process.env.REACT_APP_ACCOUNT_URL}/referrals`)
            }
          />
        </ReferralContainer>
      </QuoteStep>
    </>
  );
};

export default Sentry.withErrorBoundary(ThankYou, {
  fallback: (
    <>
      An error occurred! Please check your account page at https://account.veygo.com/profile for
      your policy status, or try refreshing the page.
    </>
  ),
});
