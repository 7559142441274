/* eslint-disable class-methods-use-this */
import * as amplitude from '@amplitude/analytics-browser';

export interface CookieYesConsentEvent extends Event {
  detail: { accepted: string[]; rejected: string[] };
}

class AmplitudeClient {
  public init(amplitudeEnabled: boolean, amplitudeKey: string): void {
    amplitude.init(amplitudeKey, {
      serverZone: 'EU',
      autocapture: false,
      optOut: !amplitudeEnabled,
    });

    document.addEventListener('cookieyes_consent_update', (event: Event) => {
      const eventData = event as CookieYesConsentEvent;
      const data = eventData.detail;

      if (data.accepted.includes('analytics')) {
        amplitude.setOptOut(false);
        this.trackEvent('web_page_viewed', { pageName: window.location.pathname });
      } else {
        amplitude.setOptOut(true);
      }
    });
  }

  public trackEvent(eventName: string, eventProperties?: object): void {
    amplitude.track(eventName, { ...eventProperties, app: 'quote' });
  }
}

export default AmplitudeClient;
