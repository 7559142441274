import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';

import { RootState } from '@redux/reducer';
import { getDriverName, getOwnerName } from '@reselectors/quote';
import { formatDate } from '@services/date';
import licenceLabelFromType from '@services/licenceTypes';
import { saveAndGoTo } from '@redux/reducer/quote/save.actions';
import { saveCustomer, setCustomerField } from '@redux/reducer/account/customer.actions';
import { setVariableExcess } from '@redux/reducer/quote/importantInformation.actions';
import { prefillDriver } from '@reducer/quote/user.actions';
import getRelationshipText from '@services/getRelationshipText';
import { addressToList } from '@services/address';
import { ProductType } from '@utils/types';

import ConfirmFastRepurchaseDetails from './ConfirmFastRepurchaseDetails';

const defaultExcesses: Record<ProductType, number> = {
  newdriver: 750,
  tc: 750,
  ldp: 250,
  csi: 750,
};

const prepareDriverDetailsData = (state: RootState) => {
  return [
    { label: 'Name', value: getDriverName(state), id: 'driverName' },
    { label: 'Date of birth', value: formatDate(state.quote.driver.birthdate), id: 'driverDob' },
    { label: 'Phone', value: state.quote.driver.phoneNumber, id: 'driverNumber' },
    { label: 'Occupation', value: state.quote.driver.occupation, id: 'driverOccupation' },
    { label: 'Address', value: addressToList(state.quote.driver.address), id: 'driverAddress' },
    {
      label: 'Licence type',
      value: licenceLabelFromType(state.quote.licence.type),
      id: 'driverLicenceType',
    },
    {
      label: 'Licence number',
      value: state.quote.licence.licenceNumber,
      id: 'driverLicenceNumber',
    },
  ];
};

const prepareVehicleInformationData = (state: RootState) => {
  const vehicleData = [
    { label: 'Car model', value: state.quote.car.model, id: 'carModel' },
    { label: 'Engine size', value: state.quote.car.engineSize, id: 'engineSize' },
    { label: 'Car reg', value: state.lookup.registrationNumber, id: 'carReg' },
  ];
  if (!state.quote.driver.isDriverOwner) {
    const ownerData = [
      { label: 'Owner name', value: getOwnerName(state), id: 'ownerName' },
      { label: 'Owner email', value: state.quote.owner.email, id: 'ownerEmail' },
      {
        label: 'Relationship to you',
        value: getRelationshipText(state.quote.user.relationship),
        id: 'ownerRelationship',
      },
    ];
    return [
      ...ownerData,
      ...vehicleData,
      { label: 'Main insurer on vehicle', value: state.quote.car.mainInsurer, id: 'mainInsurer' },
    ];
  }

  return [...vehicleData, { label: 'Owner', value: 'Policyholder' }];
};

const preparePayableExcessData = (excess: number) => {
  return [
    {
      label: 'Windscreen replacement excess',
      value: '£115',
      id: 'windscreenReplacementExcess',
    },
    {
      label: 'Windscreen repair excess',
      value: '£25',
      id: 'windscreenRepairExcess',
    },
    {
      label: 'Your selected excess',
      value: `£${excess}`,
      id: 'selectedExcess',
    },
  ];
};

const prepareDrivingHistoryData = (state: RootState) => {
  return [
    {
      label: 'Accidents or claims',
      value: state.quote.claims.hasClaims ? 'Yes' : 'No',
      id: 'accidentOrClaims',
    },
    {
      label: 'Valid No Claims Bonus',
      value: state.quote.noClaimsBonus.haveNCB ? 'Yes' : 'No',
      id: 'validNCB',
    },
    {
      label: 'Years of No Claims Bonus',
      value: state.quote.noClaimsBonus.haveNCB ? state.quote.noClaimsBonus.level : 0,
      id: 'ncbYears',
    },
  ];
};

type SummaryField = { label: string; value: string; id: string };

interface StateProps {
  driverDetailsData: SummaryField[];
  vehicleInformationData: SummaryField[];
  payableExcessData: SummaryField[];
  drivingHistoryData: SummaryField[];
  canEditDriver: boolean;
  hasEditedPersonalDetails: boolean;
  isLoggedIn: boolean;
  display30DayEditModal: boolean;
  underwriting: string;
  excess: number;
  userIdentity: string;
}

const getExcess = (state: RootState) => {
  const excess = state.quote.importantInformation.excessSelected;

  const { underwriting } = state.quote.save;
  if (!underwriting || !excess) {
    return {
      excess: defaultExcesses[state.quote.product.productType as ProductType],
      preselectedExcess: false,
    };
  }

  return { excess, preselectedExcess: true };
};

const mapStateToProps = (state: RootState): StateProps => {
  const { excess, preselectedExcess } = getExcess(state);
  const { underwriting } = state.quote.save;

  return {
    driverDetailsData: prepareDriverDetailsData(state),
    vehicleInformationData: prepareVehicleInformationData(state),
    payableExcessData: preparePayableExcessData(excess),
    drivingHistoryData: prepareDrivingHistoryData(state),
    canEditDriver: state.account.customer.canEdit,
    hasEditedPersonalDetails: state.account.customer.hasEditedPersonalDetails,
    isLoggedIn: state.account.login.loggedIn,
    display30DayEditModal: state.account.customer.display30DayEditModal,
    underwriting,
    excess,
    preselectedExcess,
    userIdentity: state.config.optimizelyAttributes.user_identity,
  };
};

interface DispatchProps {
  driverDetailsEdit: () => void;
  vehicleInformationEdit: () => void;
  payableExcessEdit: () => void;
  drivingHistoryEdit: () => void;
  submission: (hasEditedPersonalDetails: boolean, isLoggedIn: boolean) => void;
  toggleTempcoverPersonalVehicle: () => void;
  onAccept: () => void;
  onCancel: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    driverDetailsEdit: () => dispatch(push('/about-you')),
    vehicleInformationEdit: () => dispatch(push('/car')),
    payableExcessEdit: () => dispatch(push('/important-information')),
    drivingHistoryEdit: () => dispatch(push('/driving-history')),
    onAccept: () => {
      dispatch(setCustomerField('display30DayEditModal', false));
      dispatch(saveCustomer());
      dispatch(saveAndGoTo('/final-quote', true, false, true));
    },
    onCancel: () => {
      dispatch(setCustomerField('display30DayEditModal', false));
      dispatch(prefillDriver());
      dispatch(setCustomerField('hasEdited', false));
      dispatch(setCustomerField('hasEditedPersonalDetails', false));
    },
    submission: (
      hasEditedPersonalDetails: boolean,
      isLoggedIn: boolean,
      excess: number,
      preselectedExcess: boolean,
    ) => {
      if (!preselectedExcess) {
        dispatch(setVariableExcess(excess));
      }

      if (hasEditedPersonalDetails && isLoggedIn) {
        dispatch(saveCustomer());
        dispatch(saveAndGoTo('/final-quote', true, false, true));
      } else {
        dispatch(saveAndGoTo('/final-quote', true, false, true));
      }
    },
  };
};

const mergeProps = (propsFromState: StateProps, propsFromDispatch: DispatchProps): void => ({
  ...propsFromState,
  ...propsFromDispatch,
  submission: () =>
    propsFromDispatch.submission(
      propsFromState.hasEditedPersonalDetails,
      propsFromState.isLoggedIn,
      propsFromState.excess,
      propsFromState.preselectedExcess,
      propsFromState.userIdentity,
    ),
});

export interface ComponentProps extends StateProps, DispatchProps {}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(ConfirmFastRepurchaseDetails);
