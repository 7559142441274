import { buildDateForApi } from '@services/date';
import HttpClient from './httpClient';

import { customer as customerTransformer, addresses as addressTransformer } from '../transformers';

const customer = {
  get(uuid) {
    return new HttpClient.Builder().get(`/accnt/customers/${uuid}/`).execute();
  },

  update(uuid, data) {
    return new HttpClient.Builder()
      .put(`/accnt/customers/${uuid}/`)
      .data(customerTransformer.reduxToAPI(data))
      .execute();
  },

  create(username, password, customerData, addressData, unsecured = true) {
    const customerRequestData = customerTransformer.reduxToAPI(customerData);
    customerRequestData.address = addressTransformer.reduxToAPI(addressData);

    const requestData = {
      ...customerRequestData,
      username,
      password,
    };

    const request = new HttpClient.Builder().post(`/accnt/customers/`).data(requestData);

    if (unsecured) request.unsecured();

    return request.execute();
  },

  // Deprecated due to usage of two different endpoints. Use register() method instead
  registrationComplete(uuid, data) {
    return new HttpClient.Builder()
      .put(`/accnt/customers/${uuid}/registration_complete/`)
      .data(customerTransformer.reduxToAPI(data))
      .execute();
  },

  retrieveCustomerAdditionalData() {
    return new HttpClient.Builder().get('/accnt/customers/customer-additional-data/').execute();
  },

  checkEmail(email) {
    return new HttpClient.Builder().post(`/accnt/login/check_email`).data({ email }).execute();
  },
};

export default customer;
