import { connect } from 'react-redux';
import dayjs from 'dayjs';

import { login } from '@reducer/account/login.actions';
import { setClaimsField } from '@reducer/quote/claims.actions';
import { markPageAsComplete } from '@reducer/pages/actions';
import { getLast16CharactersOfDLN, isLicenceNotFound, isLicenceExpired } from '@reselectors/quote';
import { setNCBField } from '@reducer/quote/noClaimsBonus.actions';
import { setLicenceField } from '@reducer/quote/licence.actions';
import { saveAndGoTo } from '@reducer/quote/save.actions';
import { setCustomerField } from '@reducer/account/customer.actions';

import { sendEvent } from '@redux/reducer/analytics/actions';
import { ConversionProject } from '@config/constants';

import {
  reduxForm,
  formValueSelector,
  getFormSyncErrors,
  getFormInitialValues,
  getFormValues,
} from 'redux-form';

import { dlnAlreadyUsed } from '@reselectors/errors';
import { Dispatch } from 'redux';
import { RootState } from '@redux/reducer';

import DrivingHistory, {
  DrivingHistoryFields,
  DrivingHistoryProps,
  NCBFields,
} from './DrivingHistory';

const getPage = (): [string, boolean] => {
  return ['important-information', true];
};

const initialFormSelector = getFormInitialValues('drivingHistoryForm');
const formValues = getFormValues('drivingHistoryForm');
const formSelector = formValueSelector('drivingHistoryForm');

const parseStringToBoolean = (value: string) => value === 'true';
const parseStringInt = (value: string) => parseInt(value);

const submitNCBFields = (values: NCBFields, dispatch: Dispatch) => {
  const { haveNCB, level } = values;
  if (haveNCB !== null) {
    const parsedHaveNCB = parseStringToBoolean(haveNCB);
    dispatch(setNCBField('haveNCB', parsedHaveNCB));
  }
  if (level !== null) {
    const parsedLevel = parseStringInt(level);
    dispatch(setNCBField('level', parsedLevel));
  }
};

export const replaceSpecialChars = (str: string): string =>
  str.replace(/[^\w]/gi, '').replace('_', '');

export const getFirst5CharactersOfDLN = (lastName: string): string =>
  replaceSpecialChars(lastName).substring(0, 5).toUpperCase().padEnd(5, '9');

export const getNext6CharactersOfDLN = (dob: string, title: string): string | null => {
  if (title === 'mx') {
    return null;
  }
  const date = dayjs(dob);
  const day = date.format('DD');
  let month = date.format('MM');
  const year = date.format('YY');
  if (title !== 'mr') {
    month = (parseInt(month) + 50).toString().padStart(2, '0');
  }
  return year.substring(0, 1) + month + day + year.substring(1, 2);
};

// eslint-disable-next-line consistent-return
const submission = (
  values: DrivingHistoryFields,
  dispatch: Dispatch,
  props: DrivingHistoryProps,
  shouldSave = true,
) => {
  const {
    hasClaims,
    numberOfClaims,
    ncb,
    fullLicenceConfirmed,
    fullLicenceDate,
    licenceNumberSurnamePart,
    licenceNumberDOBPart,
    licenceNumberFinalPart,
  } = values;

  const parsedHasClaims = parseStringToBoolean(hasClaims);
  dispatch(setClaimsField('hasClaims', parsedHasClaims));
  // eslint-disable-next-line no-unused-expressions
  numberOfClaims ? dispatch(setClaimsField('numberOfClaims', numberOfClaims)) : null;
  if (fullLicenceConfirmed) {
    dispatch(setLicenceField('fullLicenceConfirmed', fullLicenceConfirmed));
    dispatch(setLicenceField('fullLicenceDate', fullLicenceDate));
    dispatch(setCustomerField('licence.fullLicenceConfirmed', fullLicenceConfirmed));
    dispatch(setCustomerField('licence.fullLicenceDate', fullLicenceDate));
  }

  let updatedLicenceNumber = '';

  if (props.isLoggedIn === false) {
    updatedLicenceNumber = licenceNumberSurnamePart + licenceNumberDOBPart + licenceNumberFinalPart;
  } else {
    updatedLicenceNumber = props.licenceNumber;
  }

  dispatch(setLicenceField('licenceNumber', updatedLicenceNumber));

  if (ncb) submitNCBFields(ncb, dispatch);

  if (shouldSave) {
    dispatch(markPageAsComplete('drivingHistory'));

    if (props.isFastRepurchase) {
      return dispatch(saveAndGoTo('confirm-details'));
    }

    const [page, checkProductUw] = getPage(props.isSubscription, props.birthdate);

    const checkLicence = props.isLoggedIn && !props.completedEarlyLicenceCheck;

    return dispatch(saveAndGoTo(page, checkProductUw, checkLicence));
  }

  return null;
};

const mapStateToProps = (state: RootState): DrivingHistoryProps => {
  const {
    quote: {
      licence: { cassieType },
      user: { prefilledDriver },
      driver: { birthdate },
      car: { isNewCar },
      product: { productType, isSubscription },
      save: { error, isFastRepurchase },
    },
    account: {
      customer: { ncb, isQuoteForMyself },
      login: { loggedIn },
    },
    config: {
      optimizelyAttributes: { user_identity: userIdentity },
    },
    app: { completedEarlyLicenceCheck },
  } = state;

  return {
    birthdate,
    cassieType,
    customerNCB: ncb.haveNCB,
    dlnAlreadyUsed: dlnAlreadyUsed(state),
    isLicenceNotFound: isLicenceNotFound(state),
    isLicenceExpired: isLicenceExpired(state),
    isNewCar,
    hasClaims: formSelector(state, 'hasClaims') === 'true',
    haveNCB: formSelector(state, 'ncb.haveNCB') === 'true',
    level: formSelector(state, 'ncb.level'),
    licenceNumber:
      formSelector(state, 'licenceNumberSurnamePart') +
      formSelector(state, 'licenceNumberDOBPart') +
      formSelector(state, 'licenceNumberFinalPart'),
    productType,
    prefilled: prefilledDriver,
    formErrors: getFormSyncErrors('drivingHistoryForm')(state),
    isLoggedIn: loggedIn,
    licenceType: state.quote.licence.type,
    licenceNumberFinalPart: getLast16CharactersOfDLN(state),
    initialValues:
      {
        ...initialFormSelector(state),
        licenceNumberFinalPart: getLast16CharactersOfDLN(state),
      } || {},
    formValues: formValues(state) || {},
    saveError: error,
    userIdentity,
    isSubscription,
    completedEarlyLicenceCheck,
    isQuoteForMyself,
    isFastRepurchase,
  };
};

interface DispatchProps {
  goToForgottenPassword: () => void;
  goToLogin: () => void;
  sendGaErrorEvent: (label: string) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  goToForgottenPassword: () =>
    window.location.assign(
      `${process.env.REACT_APP_LOGIN_URL}/password/forgot?redirect=${window.location.href}`,
    ),
  goToLogin: () => dispatch(login('driving-history')),
  sendGaErrorEvent: (label: string) =>
    dispatch(
      sendEvent(ConversionProject.ConversionOptimization, {
        featureFlagName: ConversionProject.THIRD_PARTY_ERROR,
        featureFlagValue: label,
      }),
    ),
});

const mergeProps = (
  propsFromState: DrivingHistoryProps,
  propsFromDispatch: DispatchProps,
  ownProps: Record<string, unknown>,
) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,
  initialValues: propsFromState.initialValues,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(
  reduxForm({
    form: 'drivingHistoryForm',
    onSubmit: (values: DrivingHistoryFields, dispatch: Dispatch, props: DrivingHistoryProps) =>
      submission(values, dispatch, props),
    enableReinitialize: true,
    touchOnChange: false,
    touchOnBlur: true,
  })(DrivingHistory),
);
