import React from 'react';
import styled from 'styled-components';
import RoadClosed from '@assets/road-closed.svg';

import StepTitle from '@atoms/titles/pageTitles/StepTitle';
import { MainContainer, InnerContent, ContentContainer } from '@templates/Main';

import RejectionSidebar from '@molecules/asides/RejectionSidebar.container';
import { Button } from '@rentecarlo/component-library';
import NavBar from '@molecules/navbar/NavBar.container';

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const MobileImage = styled.img`
  width: 300px;
`;

const DisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 0px 16px 16px 16px;
`;

const SorryText = styled.p`
  font-family: ${({ theme }) => theme.standard};
  font-size: 16px;
  font-weight: 400;
  line-height: 22.65px;
  text-align: left;
  color: ${({ theme }) => theme.textPrimary};
`;

interface ComponentProps {
  isMobile: boolean;
  productType: string;
  productEmail: string;
}
const Error: React.FC<ComponentProps> = ({ productEmail }) => {
  return (
    <>
      <NavBar pathname='error' />
      <MainContainer>
        <ContentContainer>
          <InnerContent>
            <DisplayContainer>
              <StepTitle title='Oops! Something went wrong!' marginBottom='0px' />
              <ImageContainer>
                <MobileImage src={RoadClosed} />
              </ImageContainer>
              <SorryText id='error-text-sorryWeCrashed'>
                We're really sorry, but something has gone wrong most of the time this can be fixed
                by going back or clicking “Refresh page” below. <br /> <br />
                If you were in the middle of a payment, then please{' '}
                <a href={productEmail}>contact us.</a>
              </SorryText>
              <Button id='error-button-refresh' onClick={() => window.location.reload()}>
                Refresh Page
              </Button>
              <RejectionSidebar />
            </DisplayContainer>
          </InnerContent>
        </ContentContainer>
      </MainContainer>
    </>
  );
};

export default Error;
